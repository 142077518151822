import React from 'react';
import Loadable from 'react-loadable';
import PropTypes from 'prop-types';

import SEO from '../components/seo';
// import PortfolioScrollParallax from '../components/portfolio/PortfolioScrollParallax';

import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap 4.2.1
import '../css/fonts.css';
import '../components/portfolio/PortfolioScrollParallax.css';
import '../components/portfolio/PortfolioPanel.css';
import '../components/portfolio/ExpandedPortfolioPanel.css';
import '../components/portfolio/PortfolioFooter.css';
import '../components/portfolio/PortfolioHeading.css';

const delay = (ms) => {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
}

const loadingAnimation = (
    <div className="preloader-background" style={{ height: '100vh', width: '100%' }}>
        <div className="preloader-portfolio">
           <span />
           <span />
           <span />
           <span />
           <span />
        </div>
    </div>
);

const LoadingNoScrollComponent = ({ error }) => {
    if (error) {
        return (
            <div className="preloader-background" style={{ height: '100vh', width: '100%' }}>
                <div className="preloader-portfolio">
                    Something went wrong
                </div>
            </div>
        );
    }
    return loadingAnimation;
};

LoadingNoScrollComponent.propTypes = {
    error: PropTypes.any,
};

LoadingNoScrollComponent.defaultProps = {
    error: null,
};

const PortfolioWithoutFlip = Loadable({
    loader: () => delay(200).then(() => import('../components/portfolio/PortfolioWithoutFlip')),
    loading: LoadingNoScrollComponent,
    modules: ['../components/portfolio/PortfolioWithoutFlip'],
    webpack: () => [require.resolveWeak('../components/portfolio/PortfolioWithoutFlip')],
});

const LoadingComponent = ({ error }) => {
    if (error) {
        return (
            <PortfolioWithoutFlip />
        );
    }
    return loadingAnimation;
};

LoadingComponent.propTypes = {
    error: PropTypes.any,
};

LoadingComponent.defaultProps = {
    error: null,
};

const PortfolioScrollParallaxLoadable = Loadable({
    loader: () => delay(200).then(() => import('../components/portfolio/PortfolioScrollParallax')),
    modules: ['../components/portfolio/PortfolioScrollParallax'],
    webpack: () => [require.resolveWeak('../components/portfolio/PortfolioScrollParallax')],
    loading: LoadingComponent,
});

const PortfolioPage = () => (
  <div>
    <SEO title="Portfolio - Son Nguyen" keywords={['snguyenthanh', 'portfolio', 'Son Nguyen']} />
    <PortfolioScrollParallaxLoadable />
  </div>
);

export default PortfolioPage;
